<template>
  <div>
    <v-container>
      <v-row>
        <v-spacer />
        <UpdateBtn
          v-on:click="update()"
          class="mr-6 mt-4"
          :block="$vuetify.breakpoint.xs"
        />
      </v-row>
      <v-row>
        <v-text-field
          class="mt-4"
          v-model="search"
          :label="$t('search')"
        ></v-text-field>
      </v-row>
    </v-container>
    <DataTable
      v-if="alerts"
      :items="alerts"
      item-key="VarslingId"
      :headers="headers"
      :search="search"
      :loading="loading"
    >
      <template v-slot:item.StartTid="{ item }">
        {{ item.StartTid | dateFilter }}
      </template>

      <template v-slot:item.VarslingNavn="{ item }">
        {{ item.Simulering ? "[" + $t("simulated") + "]" : "" }}
        {{
          item.VarslingStatus === "Stoppet"
            ? "[" + $t("alertStatusStopped") + "]"
            : ""
        }}
        {{ item.VarslingNavn }}
      </template>

      <template v-slot:item._groupsUsed_="{ item }">
        <v-chip
          v-for="(groupUsed, index) in getGroupsUsed(item.Kommentarer)"
          :key="index"
          small
          class="ma-1"
          v-if="index < maxGroupsToShow || expandedItem === item.VarslingId"
        >
          {{ groupUsed }}
        </v-chip>
        <v-btn
          x-small
          outlined
          elevation="2"
          text
          class="ma-1"
          v-if="
            getGroupsUsed(item.Kommentarer).length > maxGroupsToShow &&
              expandedItem !== item.VarslingId
          "
          @click="expandedItem = item.VarslingId"
        >
          +
          {{
            $tc(
              "groupsPlur",
              getGroupsUsed(item.Kommentarer).length - maxGroupsToShow
            )
          }}
        </v-btn>
        <v-btn
          x-small
          class="ma-1"
          v-if="expandedItem === item.VarslingId"
          @click="expandedItem = null"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="!rowIsSelected(item) || $vuetify.breakpoint.smAndDown"
          icon
          outlined
          @click="selectAlert(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </DataTable>
  </div>
</template>

<script>
import UpdateBtn from "@/components/misc/UpdateBtn.vue";
import store from "@/store";
import search from "@/util/search";
import config from "@/config/config";
import dateFilter from "../../filters/dateFilter";
import AlertMeta from "@/util/AlertMeta";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "AlertListTable",
  components: { DataTable, UpdateBtn },

  props: {
    // Set to status value as used in API:
    // "Aktiv", "Planlagt", "Stoppet", "Historisk"
    alertStatus: {
      type: String,
      required: false,
      default: ""
    }
  },

  computed: {
    alerts() {
      if (store.state.alertList === null) {
        return [];
      }
      if (this.alertStatus === "") {
        return store.state.alertList;
      }
      return store.state.alertList.filter(
        alert => alert.VarslingStatus === this.alertStatus
      );
    },
    headers() {
      let headers = [
        {
          text: this.$t("startTime"),
          value: "StartTid"
        },
        { text: this.$t("name"), value: "VarslingNavn" },
        {
          text: this.$t("groupsUsed"),
          value: "_groupsUsed_",
          sortable: false,
          hide: "smAndDown"
        },
        {
          text: this.$t("receivers"),
          value: "AntallMottakere",
          hide: "smAndDown"
        },
        { text: "", value: "actions", sortable: false }
      ];
      return headers.filter(h => !h.hide || !this.shouldColBeVisible(h.hide));
    },
    themeStyles() {
      return this.$vuetify.theme.dark
        ? {
            "--row-selected": "#757575",
            "--simulated": "#899470",
            "--cancelled": "#704949",
            "--selected-and-sim": "#5b6540"
          }
        : {
            "--row-selected": "#bdbdbd",
            "--simulated": "#ebf1ad",
            "--cancelled": "#e0b1aa",
            "--selected-and-sim": "#b1bc69"
          };
    }
  },

  watch: {
    alerts: {
      immediate: true,
      handler() {
        if (!this.alerts || this.alerts.length === 0) {
          return;
        }
        // When alert list is ready: Show the alert if it was set in the query (user is coming from alert page)
        if (this.targetAlertFromQuery) {
          const preSelctAlert = store.state.alertList.find(
            alert => alert.VarslingId === this.targetAlertFromQuery
          );
          if (preSelctAlert) {
            this.selectAlert(preSelctAlert);
          }
          // Must clear so that we don't jump back to this alert when the user updates the list on a different alert
          this.targetAlertFromQuery = null;
        }
      }
    }
  },

  created() {
    if (this.$route.query?.alertId) {
      this.targetAlertFromQuery = this.$route.query?.alertId;
    }
  },

  data: () => ({
    targetAlertFromQuery: null,
    loading: false,
    search: "",
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    },
    dateFilter: dateFilter,
    expandedItem: null,
    selectedAlert: null,
    maxGroupsToShow: 3
  }),

  methods: {
    update() {
      this.loading = true;
      store.dispatch("updateAlertList").then(() => {
        this.loading = false;
      });
    },
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    rowClasses(item) {
      return {
        cancelled: item.VarslingStatus === "Stoppet",
        simulated: item.Simulering,
        rowSelected: this.rowIsSelected(item),
        selectedAndSim: this.rowIsSelected(item) && item.Simulering
      };
    },
    shouldColBeVisible(breakpoint) {
      return this.$vuetify.breakpoint[breakpoint];
    },
    rowIsSelected(row) {
      if (!this.selectedAlert) {
        return false;
      }
      return row.VarslingId === this.selectedAlert.VarslingId;
    },
    getGroupsUsed(metaComments) {
      const alertMeta = new AlertMeta();
      return alertMeta.getGroupsUsed(metaComments);
    },
    selectAlert(alert) {
      this.selectedAlert = alert;
      this.$emit("alertSelected", alert.VarslingId);
    }
  }
};
</script>
<style scoped>
.rowSelected {
  background-color: var(--row-selected);
  font-weight: bold;
}
.simulated {
  background-color: var(--simulated);
}
.cancelled {
  background-color: var(--cancelled);
}
.selectedAndSim {
  background-color: var(--selected-and-sim);
}
</style>
