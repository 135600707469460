<template>
  <div>
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12 lg6>
          <AlertList v-on:alertselected="showAlertDetails" />
        </v-flex>
        <v-flex md12 lg6>
          <v-card>
            <!-- See also AlertDetails in smAndDown-dialog futher down -->
            <AlertDetails
              v-if="alertId && $vuetify.breakpoint.lgAndUp"
              :alert-id="alertId"
            />
          </v-card>
          <!-- AlertDetails as dialog on smaller screens -->
          <v-dialog
            v-model="dialog"
            v-if="$vuetify.breakpoint.mdAndDown"
            scrollable
            fullscreen
          >
            <AlertDetails
              v-if="alertId"
              :alert-id="alertId"
              :show-card-actions="true"
              v-on:close="dialog = false"
            />
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import AlertList from "../components/alertLog/AlertList";
import AlertDetails from "../components/alertLog/AlertDetails";
export default {
  name: "Log",
  components: { AlertDetails, AlertList },
  data: () => ({
    dialog: false,
    alertId: null
  }),
  methods: {
    showAlertDetails(alertId) {
      this.alertId = alertId;
      this.dialog = true;
    }
  }
};
</script>
