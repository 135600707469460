<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $t("alerts") }}
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="activeTab" show-arrows>
        <v-tab :key="1" @click="update">{{ $t("all") }}</v-tab>
        <v-tab :key="2" @click="update">{{ $t("alertStatusStarted") }}</v-tab>
        <v-tab :key="3" @click="update">{{ $t("alertStatusCompleted") }}</v-tab>
        <v-tab :key="4" @click="update()">
          {{ $t("alertStatusRegistered") }}
        </v-tab>
        <v-tab-item :key="1">
          <AlertListTable v-on:alertSelected="selectAlert" />
        </v-tab-item>
        <v-tab-item :key="2">
          <AlertListTable
            alert-status="Aktiv"
            v-on:alertSelected="selectAlert"
          />
        </v-tab-item>
        <v-tab-item :key="3">
          <AlertListTable
            alert-status="Utført"
            v-on:alertSelected="selectAlert"
          />
        </v-tab-item>
        <v-tab-item :key="4">
          <AlertListTable
            alert-status="Planlagt"
            v-on:alertSelected="selectAlert"
          />
        </v-tab-item>
      </v-tabs>
      <!-- Just to please IntellJ IDE inspection -->
      <span
        style="display: none"
        class="rowSelected selectedAndSim simulated"
      ></span>
    </v-card-text>
  </v-card>
</template>

<script>
import dateFilter from "../../filters/dateFilter";
import LoadingSpinner from "../misc/LoadingSpinner";
import store from "../../store";
import config from "@/config/config";
import search from "@/util/search";
import AlertMeta from "@/util/AlertMeta";
import AlertListTable from "@/components/alertLog/AlertListTable.vue";
import user from "@/util/user";
import apiAlert from "@/api/v24/api.alert";

export default {
  name: "AlertList",
  components: { AlertListTable, LoadingSpinner },

  watch: {
    activeTab(newVal) {
      if (newVal === null) {
        return;
      }
      this.storeTabSelected();
    }
  },

  computed: {
    completedAlerts() {
      if (!store.state.alertList) {
        return null;
      }
      return store.state.alertList.filter(
        object =>
          object.VarslingStatus !== "Planlagt" &&
          object.VarslingStatus !== "Stoppet" &&
          object.Simulering === false
      );
    },
    planedAndStoppedAlerts() {
      if (!store.state.alertList) {
        return null;
      }
      return store.state.alertList.filter(
        object =>
          (object.VarslingStatus === "Planlagt" ||
            object.VarslingStatus === "Stoppet") &&
          object.Simulering === false
      );
    },
    simulatedAlerts() {
      if (!store.state.alertList) {
        return null;
      }
      return store.state.alertList.filter(object => object.Simulering === true);
    }
  },

  data: () => ({
    loading: true,
    activeTab: null
  }),

  created() {
    this.update();
    if (this.$route.query.alertId) {
      this.selectAlert(this.$route.query.alertId);
      this.activeTab = 0;
    } else {
      this.activeTab = user.getAlertLogTab();
    }
  },

  methods: {
    selectAlert(alertId) {
      this.$emit("alertselected", alertId);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    update() {
      this.loading = true;
      store
        .dispatch("updateAlertList")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    storeTabSelected() {
      user.setAlertLogTab(this.activeTab);
    }
  }
};
</script>
